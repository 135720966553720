import ReactGA from 'react-ga4';

import { trackingId } from '../../../../shared';


ReactGA.initialize(trackingId);
// ReactGA.set({
//     // any data that is relevant to the user session
//     // that you would like to track with google analytics
// });
